import React from "react";
import Img from "react-image";
import Footer from "../../Footer/View/Footer";
import { LocationsData } from "../Static/Locations";
import { useTranslation } from "react-i18next";
import "./Less/Locations.css";

const Locations = () => {
    const { t } = useTranslation();
    return (
        <div className="locations-page">
            <div className="locations">
                {LocationsData.map((loc, index) => (
                    <div className="card-container" key={`${index}-location-card`}>
                        <div className="locations__card">
                            <div className="locations__card--image">
                                <Img src={loc.image} alt={loc.title} />
                            </div>
                            <div className="locations__card--text">{t(loc.title)}</div>
                            <div className="locations__card--description">{t(loc.description)}</div>
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default Locations;
