import React from "react";
// import Select from "react-select";
// import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

const ReservationView = (props) => {
    const { t } = useTranslation();

    if (props.showAlert) {
        props.setAlert();
        alert(t("alertMessage"));
    }

    const reservationHeader = () => {
        return (
            <div className="reservation-box__header">
                <div className="header__banner">
                    <p>{t("reservationTitle")}</p>
                </div>
                <p className="header__detail">{t("reservationText")} </p>
            </div>
        );
    };

    const reservationForm = () => {
        return (
            <div className="reservation-box__form">
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="firstLine">
                        <label htmlFor={props.form.name.id}>{t("question1")}</label>
                        <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Nagy István"
                            id={props.form.name.id}
                            value={props.form.name.value}
                            onChange={props.onChange}
                        />
                    </div>

                    <div className="secondLine">
                        <label htmlFor={props.form.tel.id}>{t("question2")}</label>
                        <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="+36 30 222 222"
                            id={props.form.tel.id}
                            value={props.form.tel.value}
                            onChange={props.onChange}
                        />
                    </div>

                    <div className="secondLine">
                        <label htmlFor={props.form.email.id}>{t("question3")}</label>
                        <input
                            type="email"
                            required
                            className="form-control"
                            placeholder="pelda@gmail.com"
                            id={props.form.email.id}
                            value={props.form.email.value}
                            onChange={props.onChange}
                        />
                    </div>
                    {/* <div className="secondLine">
						<label htmlFor={props.form.email.id}>{t("question6")}</label>
						<Select
							value={props.form.location.value}
							options={locationOptions}
							className="reservation-box__form--select"
							classNamePrefix="select-input"
							placeholder={t("selectPlaceholder")}
							onChange={(option) => {
								const e = {
									target: {
										id: props.form.location.id,
										value: option
									}
								};
								props.onChange(e);
							}}
						/>
					</div> */}

                    <div className="thirdLine">
                        <label htmlFor={props.form.date.id}>{t("question4")}</label>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder="2019.09.12"
                                    id={props.form.date.id}
                                    value={props.form.date.value}
                                    onChange={props.onChange}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <input
                                    className="date__hour form-control"
                                    placeholder="19.20"
                                    type="text"
                                    required
                                    id={props.form.time.id}
                                    value={props.form.time.value}
                                    onChange={props.onChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="fourthLine">
                        <label htmlFor={props.form.personCount.id}>{t("question5")}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="5"
                            required
                            id={props.form.personCount.id}
                            value={props.form.personCount.value}
                            onChange={props.onChange}
                        />
                    </div>

                    <div className="fourthLine">
                        <label htmlFor={props.form.personCount.id}>{t("question6")}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("question6")}
                            required
                            id={props.form.comment.id}
                            value={props.form.comment.value}
                            onChange={props.onChange}
                        />
                    </div>

                    <div className="lastLine">
                        <div className="row">
                            <div className="col-12 col-md-6" />
                            <div className="col-12 col-md-6">
                                <div className="submit-box">
                                    <input
                                        className="submitButton"
                                        type="submit"
                                        value={t("resButton")}
                                        onClick={props.submitButtonHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    return (
        <div className="reservation" id="ASZTALFOGLALAS">
            <div className="reservation-gradient" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-xl-6">
                        <div className="reservation-box">
                            <div className="reservation-box__content">
                                {reservationHeader()}
                                {reservationForm()}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6" />
                </div>
            </div>
        </div>
    );
};

export default ReservationView;
