import React from "react";
import Navbar from "../../NavBar/Controller/Navbar";
import "./less/HomePage.css";

const Layout = (props) => {
    const classes = ["homePage"];
    if (props.location.pathname === "/helyszinek" || props.location.pathname === "/kitchen") {
        classes.push("no-bg-wrapper");
    }
    return (
        <div className={classes.join(" ")}>
            <div className="above">
                <Navbar />
            </div>
            {props.children}
        </div>
    );
};

export default Layout;
