import axios from "../../../Api/axios";
import WeeklyMenuModel from "../Model/WeeklyMenuModel";

class WeeklyRoutes {
    static weeklyLink = "/api/menu";
}

export default class WeeklyMenuDAO {
    static getWeeklyMenu = async () => {
        const response = await axios.getInstance().get(WeeklyRoutes.weeklyLink);

        // const menu = {
        //     menuPrice: 1700,
        //     weekdays: {
        //         monday: {
        //             soup: "Tejfölös szemesbableves füstölt hússal",
        //             optionA: "Cornflakes bundában sült jércemell szelet vegyes körettel",
        //             optionB: "Marhapörlölt tarhonyával",
        //             dessert: "Tiramisu kocka",
        //             dayName: "Hétfő",
        //             date: "02.03."
        //         },

        //         tuesday: {
        //             soup: "Zellerkrémleves",
        //             optionA:
        //                 "Grillezett csirkemell sajtmártással, baconos zöldbabrőzsével, burgonyakrokett",
        //             optionB: "Bakonyi sertésragu galuskával",
        //             dessert: "Barackos túró szelet",
        //             dayName: "Kedd",
        //             date: "02.04."
        //         },
        //         wednesday: {
        //             soup: "Újházi tyúkhúsleves",
        //             optionA: "Rozmaringos sült csirkecombfilé póréhagymamártással, burgonyapürével",
        //             optionB: "Cordon bleu sertés szelet jázmin rizzsel",
        //             dessert: "Habroló",
        //             alternateDish1:
        //                 "Körömpörkölt petrezselymes főtt burgonyával, csemege uborkával",
        //             dayName: "Szerda",
        //             date: "02.05."
        //         },
        //         thursday: {
        //             soup: "Bársonyos cherrys meggyleves",
        //             optionA:
        //                 "Sajttal, aszalt áfonyával töltött jércemell házi morzsában sütve, sült burgonyával",
        //             optionB: "Erdei gombákkal főtt szarvasragu petrezselymes főtt burgonyával",
        //             dessert: "Gesztenyés csoki csemege",
        //             alternateDishPrice: 2400,
        //             alternateDishName: "ZS menü",
        //             alternateDish1: "Lengyel tojás",
        //             alternateDish2: "Marhahúsleves maceszgombóccal",
        //             alternateDish3: "Sólet füstölt libamellel",
        //             dayName: "Csütörtök",
        //             date: "02.06."
        //         },
        //         friday: {
        //             soup: "Zsályás citromos kacsaraguleves",
        //             optionA:
        //                 "Mozzarellás-pármai sonkás jércemell friss salátával, setak burgonyával",
        //             optionB: "Tengeri tőkehalfilé zöldfűszeres morzsában sütve, dollár chipsel",
        //             dessert: "Képviselő fánk",
        //             alternateDish1:
        //                 "Pacalpörkölt petrezselymes főtt burgonyával, csemege uborkával",
        //             dayName: "Péntek",
        //             date: "02.07."
        //         }
        //     }
        // };
        return new WeeklyMenuModel(response.data);
    };
}
