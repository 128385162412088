import React, { useEffect } from "react";
import Layout from "./Components/Layout/View/Layout";
import MainPage from "./Components/Layout/View/MainPage";
import Locations from "./Components/Locations/View/Locations";
import { withRouter, Switch, Route } from "react-router-dom";
import MenuController from "./Components/Menu/Controller/MenuController";
import DrinkMenuController from "./Components/Menu/Controller/DrinkMenuController";

function App(props){
    useEffect(
        () => {
            window.scrollTo(0, 0);
        },
        [ props.location.pathname ]
    );

    return (
        <Layout location={props.location}>
            <Switch>
                <Route path="/rendezvenyek" component={Locations} />
                <Route path="/etlap" exact component={MenuController} />
                <Route path="/itallap" exact component={DrinkMenuController} />
                <Route path="*" component={MainPage} />
            </Switch>
        </Layout>
    );
}

export default withRouter(App);
