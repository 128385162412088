import MenuDayModel from "./MenuDayModel";

export default class WeeklyMenuModel {
    menuPrice = 1000;
    weekDays = [];

    constructor(weeklyMenu) {
        if (!weeklyMenu) return;
        this.menuPrice = weeklyMenu.price;
        this.weekDays = weeklyMenu.menuItems.map((item) => new MenuDayModel(item));
    }
}
