import axios from "../../../Api/axios";

class MenuRoutes {
    static menuLink = "/api/carte";
    static drinkMenuLink = "/api/drink";
}

export default class MenuDAO {
    static getMenu = async () => {
        const response = await axios.getInstance().get(MenuRoutes.menuLink);
        return response.data;
    };

    static getDrinkMenu = async () => {
        const response = await axios.getInstance().get(MenuRoutes.drinkMenuLink);
        return response.data;
    };
}
