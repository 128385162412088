import * as React from "react";
import Footer from "../../Footer/View/Footer";
import { useTranslation } from "react-i18next";
import "./Less/DrinkMenuView.css";
import { Spinner } from "reactstrap";

const DrinkMenuView = (props) => {
    const { t } = useTranslation();

    return (
        <div className="under">
            <div className="drink-menu-wrapper">
                <h1>{t("Itallap")}</h1>
                <div className="menu-content">
                    {props.loading ? (
                        <div className="spinner-wrapper">
                            <Spinner />
                        </div>
                    ) : (
                        props.menu.map((category, index) => {
                            return (
                                <React.Fragment key={`category-${index}`}>
                                    <h2>{category.category}</h2>
                                    {!category.has_subcategory && (
                                        <div className="menu-item">
                                            <div className="menu-item__left" />
                                            <div className="menu-item__right">
                                                {category.units.map((unit, index) => {
                                                    return (
                                                        <div
                                                            className="price"
                                                            key={`unit-${index}`}
                                                        >
                                                            {unit}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                    {category.items.map((menuItem, index) => {
                                        if (menuItem.hasOwnProperty("subcategory")) {
                                            return (
                                                <React.Fragment key={menuItem.subcategory}>
                                                    <h3>{menuItem.subcategory}</h3>
                                                    <div className="menu-item">
                                                        <div className="menu-item__left" />
                                                        <div className="menu-item__right">
                                                            {category.units.map((unit) => {
                                                                return (
                                                                    <div className="price">
                                                                        {unit}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    {menuItem.items.map((item, index) => {
                                                        return (
                                                            <div
                                                                className="menu-item"
                                                                key={`${menuItem.subcategory}-${index}`}
                                                            >
                                                                <div className="menu-item__left">
                                                                    {item.name ||
                                                                        t("missingFoodName")}
                                                                    <p>{item.desc1}</p>
                                                                    <p>{item.desc2}</p>
                                                                </div>
                                                                <div className="menu-item__right">
                                                                    {item.prices.map((price) => {
                                                                        return (
                                                                            <div className="price">
                                                                                {price ? (
                                                                                    price + " Ft"
                                                                                ) : null}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            );
                                        } else
                                            return (
                                                <div
                                                    className="menu-item"
                                                    key={`menu-item-${menuItem.category}-${index}`}
                                                >
                                                    <div className="menu-item__left">
                                                        {menuItem.name || t("missingFoodName")}
                                                        <p>{menuItem.desc1}</p>
                                                        <p>{menuItem.desc2}</p>
                                                    </div>
                                                    <div className="menu-item__right">
                                                        {menuItem.prices.map((price) => {
                                                            return (
                                                                <span className="price">
                                                                    {price ? price + " Ft" : null}
                                                                </span>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                    })}
                                </React.Fragment>
                            );
                        })
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DrinkMenuView;
