import React, { Component } from "react";
import DesktopNavBar from "../View/DesktopNavBar";
import MobileNavBar from "../View/MobileNavBar";
import { withRouter } from "react-router-dom";
// import SurvivorModal from "../../Survivor/View/Survivor";

class Navbar extends Component {
    state = {
        modal: true
    };

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    };

    render() {
        return (
            <React.Fragment>
                {/* <SurvivorModal modal={this.state.modal} toggle={this.toggle} /> */}
                <DesktopNavBar toggle={this.toggle} history={this.props.history} />
                <MobileNavBar toggle={this.toggle} history={this.props.history} />
            </React.Fragment>
        );
    }
}

export default withRouter(Navbar);
