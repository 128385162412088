import axiosOriginal from "axios";
import Cookie from "js-cookie";

export default class axios {
    static instance;

    static initAxios(baseURL) {
        this.instance = axiosOriginal.create({
            baseURL
        });

        axios.instance.interceptors.request.use(function(config) {
            let lang = Cookie.get("lang");
            if (!lang) lang = "hu";
            config.headers["Accept-Language"] = lang;

            return config;
        });
    }

    static getInstance() {
        return this.instance;
    }
}
