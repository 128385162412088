import React from "react";
import { useTranslation } from "react-i18next";

const WeeklyMenuView = (props) => {
    const { t } = useTranslation();

    const menuList = props.menu.weekDays.map((data, i) => {
        return (
            <div key={`${i}-${data.day}-menu`} className="menuList">
                <div className="menuList__Date">
                    <p>
                        <span className="Date__day">{t(data.dayName)}</span> <br />
                        <span className="Date__date">{data.date}</span>
                    </p>
                </div>
                <div className="menuList__menu">
                    <div className="menuList__menu--fixed">
                        <div className="option">{data.soup} </div>
                        <div className="option option--ab">
                            <span className="optiontype optiontype--A">A</span>
                            {data.optionA}{" "}
                        </div>
                        <div className="option option--ab">
                            <span className="optiontype optiontype--B">B</span>
                            {data.optionB}
                        </div>
                        <div className="option">{data.dessert}</div>
                    </div>
                    <div className="menuList__menu--optional">
                        {(data.alternateDishName ||
                            data.alternateDishPrice ||
                            data.alternateDish1 ||
                            data.alternateDish2 ||
                            data.alternateDish3) && (
                            <div className="special-offer">
                                <h3>Speciális ajánlat</h3>
                                {
                                    <span className="special-offer--description">
                                        {data.alternateDishName}{" "}
                                        {data.alternateDishName &&
                                            data.alternateDishPrice &&
                                            "-"}{" "}
                                        {data.alternateDishPrice ? (
                                            data.alternateDishPrice + " Ft"
                                        ) : null}
                                    </span>
                                }
                            </div>
                        )}
                        {data.alternateDish1 && <div className="option">{data.alternateDish1}</div>}
                        {data.alternateDish2 && <div className="option">{data.alternateDish2}</div>}
                        {data.alternateDish3 && <div className="option">{data.alternateDish3}</div>}
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="weeklyMenu" id="HETI_MENU">
            <div className="menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-xl-12">
                            <div className="menu__box">
                                <div className="menu__box__banner">
                                    <p>
                                        <span className="menu__title">{t("weeklyTitle")}</span>
                                        <br />
                                        <span className="menu__price">
                                            {t("weeklyPrice")} {props.menuPrice} Ft
                                        </span>
                                    </p>
                                </div>
                                <div className="menu__box__menuList">
                                    {props.error ? t("failLoad") : menuList}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeeklyMenuView;
