import React from "react";
import ReactDOM from "react-dom";
import axios from "./Api/axios";
import "bootstrap/dist/css/bootstrap.min.css";
/*import 'bootstrap/dist/js/bootstrap.bundle.min';*/
import "./index.css";
import "./i18n.js";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import DefaultAxios from "axios";

DefaultAxios.get("/api/rest")
    .then((response) => {
        axios.initAxios(response.data.REST_API_URL);

        const app = (
            <BrowserRouter>
                <App />
            </BrowserRouter>
        );

        ReactDOM.render(app, document.getElementById("root"));
    })
    .catch((error) => {
        ReactDOM.render(
            <div>
                <h2>Sajnáljuk, hiba történt</h2>
                <p>Talán a következő üzenet segíthet:</p>
                <p>{error.message}</p>
                {console.error(error)}
            </div>,
            document.getElementById("root")
        );
    });

serviceWorker.unregister();
