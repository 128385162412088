import * as React from "react";
import Footer from "../../Footer/View/Footer";
import { useTranslation } from "react-i18next";
import "./Less/MenuView.css";
import { Spinner } from "reactstrap";

const MenuView = (props) => {
    const { t } = useTranslation();
    return (
        <div className="under">
            <div className="menu-wrapper">
                <h1>{t("Étlap")}</h1>
                <div className="menu-content">
                    {props.loading ? (
                        <div className="spinner-wrapper">
                            <Spinner />
                        </div>
                    ) : (
                        props.menu.map((category, index) => {
                            return (
                                <React.Fragment key={`category-${index}`}>
                                    <h2>{category.category}</h2>
                                    {category.items &&
                                        category.items.length &&
                                        category.items.map((menuItem, index) => {
                                            if (menuItem.hasOwnProperty("subcategory")) {
                                                return (
                                                    <React.Fragment key={`menu-element-${index}`}>
                                                        <h3>{menuItem.subcategory}</h3>
                                                        {menuItem.items.map((item, index) => {
                                                            return (
                                                                <div
                                                                    className="menu-item"
                                                                    key={`menu-item-${index}`}
                                                                >
                                                                    <div className="menu-item__left">
                                                                        {item.name ||
                                                                            t("missingFoodName")}
                                                                        <p>{item.description}</p>
                                                                    </div>
                                                                    <div className="menu-item__right">
                                                                        {item.price ||
                                                                            t("missingFoodPrice")}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                );
                                            } else
                                                return (
                                                    <div
                                                        className="menu-item"
                                                        key={`menu-element-${index}`}
                                                    >
                                                        <div className="menu-item__left">
                                                            {menuItem.name || t("missingFoodName")}
                                                            <p>{menuItem.description}</p>{" "}
                                                        </div>
                                                        <div className="menu-item__right">
                                                            {menuItem.price ||
                                                                t("missingFoodPrice")}
                                                        </div>
                                                    </div>
                                                );
                                        })}
                                </React.Fragment>
                            );
                        })
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MenuView;
