import React from "react";
import "./less/Footer.css";
import FooterLogo from "../../../Assets/logo_footer.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-xl-3">
                        <div className="footer__logo">
                            <img src={FooterLogo} alt="footer_logo" />
                        </div>
                    </div>
                    <div className="col-12 col-xl-6">
                        <div className="footer__middleContent">
                            <div className="footer__middleContent__above">
                                <div className="footer__middleContent__above--left">
                                    <p className="middleContent__title">{t("openHoursText")}</p>
                                    <p className="middleContent__detailText">
                                        {t("openHoursDate1")} <br /> {t("openHoursTime1")}
                                    </p>
                                    <p className="middleContent__detailText">
                                        {t("openHoursDate2")} <br /> {t("openHoursTime2")}
                                    </p>
                                </div>
                                <div className="footer__middleContent__above--mid">
                                    <p className="middleContent__title">{t("availabilityTitle")}</p>
                                    <p className="middleContent__detailText">
                                        {t("availabilityStreet")} <br />
                                        {t("availabilityPhone")}
                                    </p>
                                </div>
                                <div className="footer__middleContent__above--right">
                                    <p className="middleContent__title">{t("followUsTitle")}</p>
                                    <div className="middleContent__detailIcons">
                                        {/* <i className="flaticon-twitter-logo-silhouette" />
                                        <i className="flaticon-instagram" /> */}
                                        <a
                                            href={"https://www.facebook.com/beerandwurstdebrecen/"}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <i className="flaticon-facebook-logo" />
                                        </a>
                                        <a
                                            href="https://www.instagram.com/beerandwurstdebrecen/"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <i className="flaticon-instagram" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="footer__middleContent__under">
                                <div className="footer__middleContent__under__content">
                                    <div className="copyright-icon">
                                        <i className="flaticon-copyright" />
                                    </div>
                                    <p className="copyright-text">{t("footerText")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3" />
                </div>
            </div>
        </div>
    );
};

export default Footer;
