import Irish from "../../../Assets/location1.jpg";
import GB from "../../../Assets/location2.jpg";
import Bajor from "../../../Assets/location3.jpg";
import Czech from "../../../Assets/location4.jpg";

export const LocationsData = [
    {
        image: Czech,
        title: "location1Title",
        description: "location1Desc"
    },
    {
        image: Bajor,
        title: "location2Title",
        description: "location2Desc"
    },
    {
        image: Irish,
        title: "location3Title",
        description: "location3Desc"
    },
    {
        image: GB,
        title: "location4Title",
        description: "location4Desc"
    }
];
