export default class MenuDayModel {
    constructor(day) {
        this.soup = day.soup;
        this.optionA = day.mainCourse;
        this.optionB = day.mainCourseB;
        this.dessert = day.dessert;
        this.alternateDishPrice = day.alternateDishPrice;
        this.alternateDishName = day.alternateDishName;
        this.alternateDish1 = day.alternateDish1;
        this.alternateDish2 = day.alternateDish2;
        this.alternateDish3 = day.alternateDish3;
        this.dayName = day.day;
        this.date = day.date;
    }
}
