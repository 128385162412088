import React from "react";
import WeeklyMenuView from "../View/WeeklyMenuView";
import "../View/less/WeeklyMenu.css";
import WeeklyMenuDAO from "../DAO/WeeklyMenuDAO";
import WeeklyMenuModel from "../Model/WeeklyMenuModel";

class WeeklyMenuController extends React.Component {
    state = {
        menuPrice: "",
        menu: new WeeklyMenuModel(),
        error: null
    };

    componentDidMount() {
        this.fetchWeeklyMenu();
    }

    fetchWeeklyMenu = () => {
        WeeklyMenuDAO.getWeeklyMenu()
            .then((weeklyMenu) => {
                this.setState({ menu: weeklyMenu, menuPrice: weeklyMenu.menuPrice });
            })
            .catch((error) => {
                this.setState({ error: "failLoad" });
            });
    };

    render() {
        return (
            <WeeklyMenuView
                menu={this.state.menu}
                menuPrice={this.state.menuPrice}
                error={this.state.error}
            />
        );
    }
}

export default WeeklyMenuController;
