const ReservationForm = {
    name: {
        id: "name",
        value: "",
        required: true
    },
    tel: {
        id: "tel",
        value: "",
        required: true
    },
    email: {
        id: "email",
        value: "",
        required: true
    },
    comment: {
        id: "comment",
        value: "",
        required: true
    },
    date: {
        id: "date",
        value: "",
        required: true
    },
    time: {
        id: "time",
        value: "",
        required: true
    },
    personCount: {
        id: "personCount",
        value: "",
        required: true
    }
};

export default ReservationForm;
