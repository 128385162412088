import React from "react";
import MenuView from "../View/MenuView";
import MenuDAO from "../DAO/MenuDAO";

class MenuController extends React.Component {
    state = {
        loading: false,
        error: null,
        menu: [
            {
                category: "",
                items: [
                    {
                        name: "",
                        price: 0
                    },
                    {
                        name: "",
                        price: 0
                    }
                ]
            }
        ]
    };

    componentDidMount() {
        this.getMenu();
    }

    getMenu = () => {
        this.setState({ loading: true }, () => {
            MenuDAO.getMenu()
                .then((res) => this.setState({ loading: false, menu: res }))
                .catch((error) => this.setState({ error, loading: false }));
        });
    };
    render() {
        return <MenuView {...this.state} />;
    }
}

export default MenuController;
