import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HU from "./Assets/i18n/HU.json";
import EN from "./Assets/i18n/EN.json";
import Cookies from "js-cookie";

let languageFromCookie = "hu";
export class Lang {
    static lang = "hu";
}

const newLang = Cookies.get("lang");
if (newLang) {
    languageFromCookie = newLang;
    Lang.lang = newLang;
}

i18n.use(initReactI18next).init({
    resources: {
        hu: {
            translation: HU
        },
        en: {
            translation: EN
        }
    },
    lng: languageFromCookie,
    fallbackLng: "hu",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
